<template>
  <div class="col-4">
    <h1>Edit {{ exchange.name }} </h1>
    <div>
      <form>
        <div class="form-group my-2">
          <label for="nameInput">Name</label>
          <input type="text" class="form-control" id="nameInput" placeholder="Name" required v-model="newExchange.name">
        </div>
        <div class="form-group my-2">
          <label for="endpointInput">Exchange Template ID</label>
          <input type="text" class="form-control" id="endpointInput" aria-describedby="endpointHelp" placeholder="{{exchange.id}}" disabled v-model="exchange.id">
          <small id="endpointHelp" class="form-text text-muted">Cannot be changed and is loaded from template.</small>
        </div>
        <div class="form-group my-2">
          <label for="apikeyInput">API-Key</label>
          <input type="text" class="form-control" id="apikeyInput" placeholder="API Key" v-model="newExchange.apiKey">
        </div>
        <div class="form-group my-2">
          <label for="apisecretInput">API-Secret</label>
          <input type="text" class="form-control" id="apisecretInput" placeholder="API Secret" v-model="newExchange.apiSecret">
        </div>
        <button type="submit" class="btn btn-primary my-2" @click.prevent="save">Save changes</button>
        <div class="form-group my-2">
          <label for="activeInput">Active</label>
          <input type="text" class="form-control" id="activeInput" aria-describedby="activeHelp" placeholder="{{exchange.is_active}}" disabled v-model="exchange.is_active">
          <small id="activeHelp" class="form-text text-muted">Change status by pressing buttons.</small>
        </div>
        <button type="submit" class="btn btn-primary my-2" @click.prevent="activate">Activate Exchange</button>
        <button type="submit" class="btn btn-primary my-2" @click.prevent="deactivate">Deactivate Exchange</button>
        <div class="form-group my-2">
          <button type="submit" class="btn btn-primary my-2 bg-danger" @click.prevent="deleteEx">Delete Exchange</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ExchangeService from "@/services/exchange.service";
import { useRoute } from 'vue-router'

export default {
  name: "ExchangeSettingsView",
  data() {
    return {
      exchange: [
          {
          name: 'BybitInstance1',
          id: 'https://api.bybit.com',
          is_active: true,
        }],
      newExchange: [
          {
            name: '',
            apiKey: '',
            apiSecret: '',

        }],
      eid: useRoute().path.split('/')[2]

    }
  },
  methods: {
    save: async function(){
      await ExchangeService.adjustExchange( this.eid, this.newExchange.name,
          this.newExchange.apiKey, this.newExchange.apiSecret)

      this.$router.push('/exchanges')
    },
    activate: async function(){
      await ExchangeService.activateExchange(this.eid)
      this.$router.push('/exchanges')
    },
    deactivate: async function(){
      await ExchangeService.deactivateExchange(this.eid)
      this.$router.push('/exchanges')
    },
    deleteEx: async function(){
      await ExchangeService.deleteExchange(this.eid)
      this.$router.push('/exchanges')
    },
    getExchange: async function(){
      const ex = await ExchangeService.getExchange(this.eid)
      this.exchange.name = ex.name
      this.exchange.id = ex.exchange_template_id
      this.exchange.is_active = ex.is_active
    }
  },
  mounted(){
    this.getExchange()
  }
}
</script>

<style scoped>

</style>
