<template>
  <div class="d-flex justify-content-center align-middle">
    <form class="border shadow p-4 rounded">
      <h1>Signup</h1>
      <div class="form-group my-2">
        <label for="exampleInputEmail1">Email address</label>
        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" required v-model="user.email">
        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
      </div>
      <div class="form-group my-2">
        <label for="exampleInputName">User Name</label>
        <input type="text" class="form-control" id="exampleInputName" placeholder="Enter user name" required v-model="user.username">
      </div>
      <div class="form-group my-2">
        <label for="exampleInputPassword1">Password</label>
        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" required v-model="user.password">
      </div>
      <div class="form-group my-2">
        <label for="exampleInputPasswordRepeat">Repeat Password</label>
        <input type="password" class="form-control" id="exampleInputPasswordRepeat" placeholder="Password (repeat)" required v-model="user.rePassword">
      </div>
      <div class="form-group my-2">
        <label for="inputPasswordRepeat">Repeat Password</label>
        <input type="password" class="form-control" id="inputPasswordRepeat" placeholder="Password (repeat)" v-model="passwordRpt">
      </div>
      <div class="form-group my-2">
        <label for="inputInvitationCode">Invitation Code</label>
        <input type="text" class="form-control" id="inputInvitationCode" placeholder="Invitation Code" v-model="invitationCode">
      </div>
      <div class="form-check my-2" v-if="false">
        <input type="checkbox" class="form-check-input" id="exampleCheck1">
        <label class="form-check-label" for="exampleCheck1">Check me out</label>
      </div>
      <button type="submit" class="btn btn-primary col-12" @click.prevent="signup">Submit</button>
      <p class="text-secondary my-3" @click.prevent="this.$router.push('/login')">Already registered? Log in.</p>
    </form>
  </div>
</template>

<script>
import AuthService from "@/services/auth.service";

export default {
  name: "SignupView",
  data() {
    return {
      successful: false,
      loading: false,
      showError: '',
      errorMessage: '',
      message: '',
      user: {
        username: '',
        email: '',
        password: '',
        rePassword: ''
      }
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  mounted() {
    if( this.loggedIn) {
      this.$router.push('/dashboard');
    }
  },
  methods: {
    signup: async function(){
      if(this.user.password === this.user.rePassword){
        await AuthService.register(this.user)
        this.$router.push('/login');
      }
      else {
        alert('Password does not match!')
      }
    },
    handleRegister(user) {
      this.message = '';
      this.successful = false;
      this.loading = true;
      this.showError = false;

      /*if (email.length === 0 || username.length === 0 || password.length === 0 || invitationcode.length === 0 ) {
        this.showError = true
        this.errorMessage = 'Not all fields are filled'
        return;
      }

      if (password !== passwordRpt) {
        this.showError = true
        this.errorMessage = 'Passwords are not equal'
        return;
      }*/

      this.$store.dispatch('auth/register', user).then(
          (data) => {
            this.message = data.message;
            this.successful = true;
            this.loading = false;
          },
          (error) => {
            this.message =
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString();
            this.successful = false;
            this.loading = false;
          }
      )

      this.$router.push('/dashboard')
    }
  }
}
</script>

<style scoped>

</style>
