import { createRouter, createWebHistory } from 'vue-router'
import ExchangesView from "@/views/exchange/ExchangesView";
import ExchangeDetailView from "@/views/exchange/ExchangeDetailView";
import DashboardView from "@/views/DashboardView";
import AddExchangeView from "@/views/exchange/AddExchangeView";
import ExchangeSettingsView from "@/views/exchange/ExchangeSettingsView";
import SymbolsView from "@/views/symbol/SymbolsView";
import SymbolEditView from "@/views/symbol/SymbolEditView";
import SymbolAddView from "@/views/symbol/SymbolAddView";
import LoginView from "@/views/auth/LoginView";
import SignupView from "@/views/auth/SignupView";
import PublicView from "@/views/public/PublicView";

const routes = [
  {
    path: '/',
    redirect: { name: 'Dashboard'}
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/signup',
    name: 'Signup',
    component: SignupView
  },
  {
    path: '/exchanges',
    name: 'exchanges',
    component: ExchangesView
  },
  {
    path: '/exchange/:eid',
    name: 'exchange',
    component: ExchangeDetailView
  },
  {
    path: '/exchange/new',
    name: 'Add exchange',
    component: AddExchangeView
  },
  {
    path: '/exchange/:eid/symbols',
    name: 'Exchange detail',
    component: SymbolsView
  },
  {
    path: '/exchange/:eid/symbols/new',
    name: 'Add symbol',
    component: SymbolAddView
  },
  {
    path: '/exchange/:eid/symbols/:sid/edit',
    name: 'Edit symbol',
    component: SymbolEditView
  },
  {
    path: '/exchange/:eid/settings',
    name: 'Edit exchange',
    component: ExchangeSettingsView
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView
  },
  {
    path: '/public',
    name: 'public',
    component: PublicView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/signup', '/public'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
})

export default router
