import axios from "axios";
//import api from "@/services/api-connector";
import apiUrl from "@/services/constants";



class AuthService {
    login(user) {
        return axios
            .get(apiUrl + '/login', {
                auth: {
                    username: user.username,
                    password: user.password
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }

                return response.data
            });
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(user) {
        console.log(user)
        return axios.post(apiUrl + '/signup', {
            username: user.username,
            email: user.email,
            password: user.password
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                alert(response.data.message)
            })
            .catch(error => {
                alert(error)
            });
    }
}

export default new AuthService();
