<template>
  <div class="col-4">
    <h1>New Symbol</h1>
    <div>
      <form @submit.prevent="createNew">
        <div class="form-group my-2">
          <label for="nameInput">Name</label>
          <input type="text" class="form-control input-sm" id="nameInput" placeholder="My first symbol" required v-model="displayName">
        </div>
        <div class="form-group my-2">
          <label for="nameInput">Pair Name</label>
          <input type="text" class="form-control" id="nameInput" placeholder="ETHUSD" required v-model="name">
          <small id="symbolInfo" class="form-text text-muted">Check correct pair name!</small>
        </div>
        <div class="row">
          <div class="form-group my-2 col">
            <label for="profitPercInput">Profit Percentage</label>
            <input type="number" step="any" class="form-control" id="profitPercInput" placeholder="0.8" required v-model="profitPerc">
          </div>
          <div class="form-group my-2 col">
            <label for="startContractsInput">Start Contracts</label>
            <input type="number" class="form-control" id="startContractsInput" placeholder="132" required v-model="startContracts">
          </div>
          <div class="form-group my-2 col">
            <label for="startSplitContractsInput">Start Split Contracts</label>
            <input type="number" class="form-control" id="startSplitContractsInput" placeholder="6" required v-model="startSplitContracts">
          </div>
        </div>
        <div class="row">
          <div class="form-group my-2 col">
            <label for="contractMultiInput">Contract Multi</label>
            <input type="number" step="any" class="form-control" id="contractMultiInput" placeholder="3" required v-model="contractMulti">
          </div>
          <div class="form-group my-2 col">
            <label for="leverageInput">Leverage</label>
            <input type="number" class="form-control" id="leverageInput" placeholder="4" required v-model="leverage">
          </div>
        </div>
        <div class="row">
          <div class="form-group my-2 col">
            <label for="backupMultiInput">Backup Multi</label>
            <input type="number" step="any" class="form-control" id="backupMultiInput" required placeholder="0.80" v-model="backupMulti">
          </div>
          <div class="form-group my-2 col">
            <label for="backupDollarInput">Backup $</label>
            <input type="number" step="any" class="form-control" id="backupDollarInput" required placeholder="0" v-model="backupDollar">
          </div>
        </div>
        <div class="row">
          <div class="form-group my-2 col">
            <label for="contractSizeInput">Contract Size $</label>
            <input type="number" step="any" class="form-control" id="contractSizeInput" required placeholder="1" v-model="contractSize">
          </div>
          <div class="form-group my-2 col">
            <label for="contractMultiInput">Contract Multi</label>
            <input type="number" step="any" class="form-control" id="contractMultiInput" required placeholder="0" v-model="contractSizeMulti">
          </div>
        </div>
        <div class="row">
          <div class="form-group my-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="splitStepInput" v-model="splitStep">
            <label class="form-check-label" for="splitStepInput">
              Split Step
            </label>
          </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group my-2 col">
            <label for="splitDistanceInput">Split Distance $</label>
            <input type="number" class="form-control" step="any" id="splitDistanceInput" required placeholder="40" v-model="splitDistance">
          </div>
          <div class="form-group my-2 col">
            <label for="splitDistancePercInput">Split Distance %</label>
            <input type="number" class="form-control" step="any" id="splitDistancePercInput" required placeholder="0" v-model="splitDistancePerc">
          </div>
        </div>
        <!--<div class="form-group my-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="trailingStopInput" v-model="trailingStop">
            <label class="form-check-label" for="trailingStopInput">
              Trailing stop
            </label>
          </div>
        </div>-->
        <!--<div class="form-group my-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="compoundingInput" v-model="compounding">
            <label class="form-check-label" for="compoundingInput">
              Compounding
            </label>
          </div>
        </div>-->

        <button type="submit" class="btn btn-primary my-2">Save changes</button>
      </form>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import SymbolService from "@/services/symbol.service"
export default {
  name: "SymbolAddView",
  data() {
    return {
      displayName: '',
      name: '',
      exchangeId: parseInt(useRoute().path.split('/')[2]),
      active: false,

      profitPerc: null,
      startContracts: null,
      startSplitContracts: null,
      contractMulti: null,
      leverage: null,

      backupMulti: null,
      backupDollar:0,

      contractSize: null,
      contractSizeMulti: 0,

      splitStep: false,
      splitDistance: 0,
      splitDistancePerc: 0
    }
  },
  methods: {
    createNew() {
      // create new in database
      SymbolService.createNewSymbol(this.displayName, this.name, this.exchangeId, this.profitPerc, this.startContracts,
      this.startSplitContracts, this.contractMulti, this.leverage, this.backupMulti, this.backupDollar, this.contractSize,
      this.contractSizeMulti, this.splitStep, this.splitDistance, this.splitDistancePerc)
      this.$router.push('/exchange/' + this.exchangeId + '/symbols')
    }
  }
}
</script>

<style scoped>

</style>
