<template>
<div class="border shadow p-2 rounded col-md-4 col-12">
  <p class="text-secondary fs-5">{{name}}</p>
  <p class="fs-2 text-end">{{value}}</p>
</div>
</template>

<script>
export default {
  name: "DashboardCardComponent",
  props: {
    name: String,
    value: Object
  }
}
</script>

<style scoped>

</style>
