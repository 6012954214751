import axios from "axios";
import authHeader from "@/services/auth-header";
import apiUrl from "@/services/constants";

class SymbolService {
    createNewSymbol(display_name, name, exchange_id, profit_perc, init_start_contract_size,
                    init_start_split_contract_size, contract_multi, leverage,
                    backup_multi, backup_dollar, contract_size, contract_size_multi,
                    use_split_step, split_distance, split_distance_perc) {
        if (!use_split_step) {
            use_split_step = false
        }
        const data = {
            display_name, name, exchange_id, profit_perc, init_start_contract_size,
            init_start_split_contract_size, contract_multi, leverage,
            backup_multi, backup_dollar, contract_size, contract_size_multi,
            use_split_step, split_distance, split_distance_perc
        }
        axios.post(apiUrl + '/symbol', data, {headers: authHeader()})
            .then(response => {
                alert(response.data.message)
            })
            .catch(error => {
                alert(error)
            })
    }

    async adjustSymbol(symbolId, display_name, name, profit_perc, init_start_contract_size,
                       init_start_split_contract_size, contract_multi, leverage,
                       backup_multi, backup_dollar, contract_size, contract_size_multi,
                       use_split_step, split_distance, split_distance_perc) {
        const data = {
            display_name, name, profit_perc, init_start_contract_size,
            init_start_split_contract_size, contract_multi, leverage,
            backup_multi, backup_dollar, contract_size, contract_size_multi,
            use_split_step, split_distance, split_distance_perc
        }
        await axios
            .put(apiUrl + '/symbol/' + symbolId, data, {headers: authHeader()})
            .then(response => {
                alert(response.data.message);
            })
            .catch(error => {
                alert(error)
            });
        return true
    }

    async deleteSymbol(symbolId) {
        await axios
            .delete(apiUrl + '/symbol/' + symbolId, {headers: authHeader()})
            .then(response => {
                alert(response.data.message)
            })
            .catch(error => {
                alert(error)
            })
        return true
    }

    async getAllSymbols(exchangeId) {
        let symbols = []
        await axios
            .get(apiUrl + '/symbol/exchange/' + exchangeId, {headers: authHeader()})
            .then(response => {
                symbols = response.data.symbols;
            })
            .catch(error => {
                alert(error)
            })
        return symbols
    }

    async getSymbol(symbolId) {
        let symbol = {}
        await axios
            .get(apiUrl + '/symbol/' + symbolId, {headers: authHeader()})
            .then(response => {
                console.log(response)
                symbol = response.data.symbol;
                console.log(symbol)
            })
            .catch(error => {
                alert(error)
            })
        return symbol
    }

    async activateSymbol(symbolId) {
        await axios
            .put(apiUrl + '/symbol/activate/' + symbolId, {}, {headers: authHeader()})
            .then(response => {
                alert(response.data.message)
            })
            .catch(error => {
                alert(error)
            })
        return true
    }

    async deactivateSymbol(symbolId) {
        await axios
            .put(apiUrl + '/symbol/deactivate/' + symbolId, {}, {headers: authHeader()})
            .then(response => {
                alert(response.data.message)
            })
            .catch(error => {
                alert(error)
            })
        return true
    }
}

export default new SymbolService();
