<template>
<div class="d-flex justify-content-center align-middle">
  <form class="border shadow p-4 rounded">
    <h1>Login</h1>
    <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
    >
      {{ message }}
    </div>
    <div class="form-group my-2">
      <label for="exampleInputEmail1">User Name</label>
      <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" v-model="username">
      <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
    </div>
    <div class="form-group my-2">
      <label for="exampleInputPassword1">Password</label>
      <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" v-model="password">
    </div>
    <div class="form-check my-2">
      <input type="checkbox" class="form-check-input" id="exampleCheck1">
      <label class="form-check-label" for="exampleCheck1">Check me out</label>
    </div>
    <button type="submit" class="btn btn-primary col-12" @click.prevent="handleLogin">Submit</button>
    <p class="text-secondary my-3" @click.prevent="this.$router.push('/signup')">Need to sign up?</p>
  </form>
</div>
</template>

<script>
export default {
  name: "LoginView",
  data() {
    return {
      loading: false,
      message: '',
      username: '',
      password: ''
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/dashboard');
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      const user = {
        username: this.username,
        password: this.password
      }
      this.$store.dispatch('auth/login', user).then(
          () => {
            this.$router.push('/dashboard');
          },
          (error) => {
            this.loading = false;
            this.message =
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString();
          }
      )
    }
  }
}
</script>

<style scoped>

</style>
