<template>
<div>
  <div class="d-flex justify-content-between">
    <h1>Exchanges</h1>
    <div>
      <button type="button" class="btn btn-primary" @click.prevent="addNew">Add exchange</button>
    </div>
  </div>
  <div class="d-flex flex-column">
   <div class="alert alert-info my-2" v-if="exchanges && exchanges.length === 0">
     <span>No Exchanges found. Use Add Exchange to add a new exchange</span>
   </div>
    <div class="d-flex justify-content-between flex-wrap p-4 border mx-2 shadow rounded" style="min-width: 45vw;" v-for="exchange in exchanges" :key="exchange.name" >
      <img src="@/assets/unnamed.png" height="180" width="180">
      <div>
        <p class="fs-2">{{exchange.name}}</p>
        <div class="d-flex">
          <div class="me-4 d-flex flex-column justify-content-between">
            <div>
              <span class="text-secondary fs-7">Template ID</span>
              <p class="fs-5">{{exchange.exchange_template_id}}</p>
            </div>
            <div>
              <span class="text-secondary fs-7">Active</span>
              <p class="fs-5">{{exchange.is_active}}</p>
            </div>
          </div>
          <div class="me-4 d-flex flex-column justify-content-between">
            <div>
              <span class="text-secondary fs-7">Created at</span>
              <p class="fs-5">{{exchange.created_at}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-between">
        <div>
          <div class="p-2 text-white rounded text-center" :class="exchange.is_active ? 'bg-success' : 'bg-danger'">
            {{exchange.is_active ? 'Running' : 'Stopped'}}
          </div>
        </div>
        <div>
          <button type="button" class="btn btn-primary col-12 my-2" @click.prevent="this.$router.push('/exchange/' + exchange.eid + '/symbols')">Symbols</button>
          <button type="button" class="btn btn-outline-primary col-12" @click.prevent="this.$router.push('/exchange/' + exchange.eid + '/settings')">Settings</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import ExchangeService from "@/services/exchange.service"

export default {
  name: "ExchangesView",
  data() {
    return {
      exchanges: [
          /*{
          "img": '@/assets/unnamed.png',
          "name": 'BybitInstance1',
          "exchange_template_id": 95312.75,
          "is_active": 12000.17,
          "created_at": 12,
          "eid": 1
        }*/
      ]
    }
  },
  methods: {
    addNew() {
      this.$router.push('/exchange/new')
    },
    getExchanges: async function(){
      this.exchanges = await ExchangeService.getAllExchanges()
    }
  },
  mounted() {
   this.getExchanges()
  }
}
</script>

<style scoped>

</style>
