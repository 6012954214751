import axios from "axios";
import authHeader from "@/services/auth-header";
import apiUrl from "@/services/constants";
//import api from "@/services/api-connector";

//const API_URL = 'http://127.0.0.1:5000/api'


class ExchangeService{
    createNewExchange(exchange_template_id, name, api_key, api_secret) {
        const data = {
            exchange_template_id,
            name,
            api_key,
            api_secret
        }
        axios.post(apiUrl + '/exchange', data, {headers: authHeader()})
        .then(response => {
                alert(response.data.message)
            })
            .catch(error => {
                alert(error)
            });
    }

    async getAllExchanges() {
        let exchanges = []
        await axios
            .get(apiUrl + '/exchange', {headers: authHeader()})
            .then(response => {
                exchanges = response.data.exchanges;
            })
            .catch(error => {
                alert(error)
            })
        return exchanges
    }

    async getExchange(exchangeId) {
        let exchange = {}
        await axios
            .get(apiUrl + '/exchange/' + exchangeId, {headers: authHeader()})
            .then(response => {
                exchange = response.data.exchange;
            })
            .catch(error => {
                alert(error)
            })
        return exchange
    }

    async adjustExchange(exchangeId, name, api_key, api_secret) {
       const data = {
           name,
           api_key,
           api_secret
        }
        await axios
            .put(apiUrl + '/exchange/' + exchangeId, data, {headers: authHeader()})
            .then(response => {
                alert(response.data.message);
            })
            .catch(error => {
                alert(error)
            });
       return true
    }

    async deleteExchange(exchangeId) {
        await axios
            .delete(apiUrl + '/exchange/' + exchangeId, {headers: authHeader()})
            .then(response => {
                alert(response.data.message)
            })
            .catch(error => {
                alert(error)
            })
        return true
    }

    async activateExchange(exchangeId) {
        await axios
            .put(apiUrl + '/exchange/activate/' + exchangeId,{}, {headers: authHeader()})
            .then(response => {
                alert(response.data.message)
            })
            .catch(error => {
                alert(error)
            })
        return true
    }

    async deactivateExchange(exchangeId) {
        await axios
            .put(apiUrl + '/exchange/deactivate/' + exchangeId,{}, {headers: authHeader()})
            .then(response => {
                alert(response.data.message)
            })
            .catch(error => {
                alert(error)
            })
        return true
    }

    async getExchangeTemplates() {
        let exchangeTemplates = {}
        await axios
            .get(apiUrl + '/exchange/exchangeTemplates', {headers: authHeader()})
            .then(response => {
                exchangeTemplates = response.data.exchangeTemplates
            })
            .catch(error => {
                return error;
            })
        return exchangeTemplates
    }


}

export default new ExchangeService();
