import axios from "axios";
import authHeader from "@/services/auth-header";

const API_URL = 'http://localhost:5000/api/test/'

class UserService {
    getPublicContent() {
        return axios.get(API_URL + 'all');
    }

    getUserBoard() {
        return axios.get(API_URL + 'user', { headers: authHeader()});
    }

    getModeratorBoard() {
        return axios.get(API_URL + 'mod', {headers: authHeader()})
    }

    getAdminBoard() {
        return axios.get(API_URL + 'admin', {headers: authHeader()})
    }

    getExchanges() {
        return axios.get(API_URL + 'exchanges', { headers: authHeader()})
    }
}

export default new UserService();
