<template>
<div class="col-lg-4">
  <h1>Add new exchange</h1>
  <div v-if="exchangeTemplates.length > 0">
    <p>Select template</p>
    <div class="d-flex flex-md-row flex-column">
      <button v-for="exchange in exchangeTemplates" :key="exchange" class="py-2 px-4 btn text-uppercase mx-2"
         :class="exchange.active ? 'btn-primary' : 'btn-secondary'" @click.prevent="selectExchange(exchange)">{{ exchange.name }}</button>
    </div>
    <hr/>
    <form @submit.prevent="createNew">
      <div class="form-group my-2">
        <label for="nameInput">Name</label>
        <input type="text" class="form-control" id="nameInput" placeholder="Name" required v-model="newExchange.name">
      </div>
      <div class="form-group my-2">
        <label for="endpointInput">Endpoint</label>
        <input type="text" class="form-control" id="endpointInput" aria-describedby="endpointHelp" placeholder="Endpoint" disabled required v-model="newExchange.endpoint">
        <small id="endpointHelp" class="form-text text-muted">Cannot be changed and is loaded from template.</small>
      </div>
      <div class="form-group my-2">
        <label for="apikeyInput">API-Key</label>
        <input type="text" class="form-control" id="apikeyInput" placeholder="API Key" required v-model="newExchange.apiKey">
      </div>
      <div class="form-group my-2">
        <label for="apisecretInput">API-Secret</label>
        <input type="text" class="form-control" id="apisecretInput" placeholder="API Secret" required v-model="newExchange.apiSecret">
      </div>
      <button type="submit" class="btn btn-primary my-2 col-sm-12" @click.prevent="createNew">Create exchange</button>
    </form>
  </div>
  <div v-if="exchangeTemplates.length === 0" class="alert alert-danger">
    <span>No exchange templates found.</span>
  </div>
</div>
</template>

<script>
import ExchangeService from "@/services/exchange.service"

export default {
  name: "AddExchangeView",
  data() {
    return {
      exchangeTemplates: [
        /*{
          name: 'BTSE',
          active: false,
          endpoint: 'https://BTSE',
          id: 1
        },
        {
          name: 'Bybit',
          active: false,
          endpoint: 'https://Bybit',
          id: 2
        },
        {
          name: 'Binance',
          active: false,
          endpoint: 'https://Binance',
          id: 3
        }*/
      ],
      newExchange: {
        name: '',
        endpoint: '',
        tempId: '',
        templateName: '',
        apiKey: '',
        apiSecret: ''
      }
    }
  },
  methods: {
    selectExchange(exchange) {
      this.exchangeTemplates.forEach((ex) => {
        ex.active = false
      })
      exchange.active = true;
      this.newExchange.endpoint = exchange.endpoint;
      this.newExchange.tempId = exchange.id;
      this.newExchange.templateName = exchange.name;
    },
    createNew() {
      // send via axios the object
      ExchangeService.createNewExchange( this.newExchange.templateName, this.newExchange.name,
          this.newExchange.apiKey, this.newExchange.apiSecret)

      this.$router.push('/exchanges');

    },
    activateFirstButton(){
      this.exchangeTemplates[0].active = true
      this.newExchange.endpoint = this.exchangeTemplates[0].endpoint;
      this.newExchange.tempId = this.exchangeTemplates[0].id;
    }
  },
  mounted() {
    ExchangeService.getExchangeTemplates()
    .then(response => {
      this.exchangeTemplates.forEach((ex) => {
        ex.active = false;
      })
      this.exchangeTemplates = response
      this.activateFirstButton()
    }).catch(error => {
      console.log(error)
      this.exchangeTemplates = []
    });
  }
}
</script>

<style scoped>

</style>
