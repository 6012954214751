<template>
  <nav class="d-flex container justify-content-between">
    <a class="navbar-brand fs-2" href="#">CLB</a>
    <div v-if="currentUser">
      <router-link to="/dashboard">Dashboard</router-link>
      |
      <router-link to="/exchanges">Exchanges</router-link>
    </div>
    <div v-if="currentUser" class="d-flex justify-content-between">
      <div>
        <p class="px-4"><font-awesome-icon icon="user"/> Username {{  currentUser.username }}</p>
      </div>
      <p class="px-4" v-if="false">{{ currentUser.accessToken.substring(0, 20) }}</p>
      <div>
        <p class="nav-link"><a @click.prevent="logOut">
          <font-awesome-icon icon="sign-out-alt" /> Logout
        </a></p>
      </div>
      <p v-if="false">
        <font-awesome-icon icon="angle-down" />
      </p>
    </div>
    <div v-if="!currentUser" class="d-flex">
      <router-link to="/login" class="px-2 nav-link">
        <font-awesome-icon icon="sign-in-alt" /> Login
      </router-link>
      <router-link to="/signup" class="px-2 nav-link">
        <font-awesome-icon icon="user-plus" /> Signup
      </router-link>
      <router-link to="/public" class="px-2 nav-link">
        Public
      </router-link>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'HeaderComponent',
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    /*if (!this.currentUser) {
      this.$router.push('/login')
    }*/
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login')
    }
  }
}
</script>
<style>

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

</style>
