<template>
<div>
  <h1>Exchange {{name}}</h1>
  <hr/>
  <div class="d-flex justify-content-between my-4">
    <h2>Symbols</h2>
    <div>
      <button type="button" class="btn btn-primary" @click.prevent="this.$router.push('/exchange/' + exchangeId + '/symbols/new')">Add symbol</button>
    </div>
  </div>
  <div class="d-flex my-3">
    <div v-for="symbol in symbols" :key="symbol.name" style="min-width: 18em" class="mx-3 p-4 border rounded" :class="symbol.active ? 'bg-success bg-opacity-50': ''">
      <div class="d-flex justify-content-between">
        <span>{{ symbol.display_name }}</span>
        <span @click.prevent="this.$router.push('/exchange/' + exchangeId + '/symbols/'+ symbol.sid +'/edit')"><font-awesome-icon icon="pen" /></span>
      </div>
      <hr/>
      <div class="d-flex justify-content-between">
        <span>Pair Name</span>
        <span>{{ symbol.name }}</span>
      </div>
      <div class="d-flex justify-content-between">
        <span>Active</span>
        <span>{{ symbol.active }}</span>
      </div>
      <div class="d-flex justify-content-between">
        <span>Profit %</span>
        <span>{{ symbol.profit_perc }}</span>
      </div>
      <div class="d-flex justify-content-between">
        <span>Start contracts</span>
        <span>{{ symbol.init_start_contract_size }}</span>
      </div>
      <div class="d-flex justify-content-between">
        <span>Start split contracts</span>
        <span>{{ symbol.init_start_split_contract_size }}</span>
      </div>
      <div class="d-flex justify-content-between">
        <span>Start split contracts</span>
        <span>{{ symbol.init_start_split_contract_size }}</span>
      </div>
      <div class="d-flex justify-content-between">
        <span>Contract Multi</span>
        <span>{{ symbol.contract_multi }}</span>
      </div>
      <div class="d-flex justify-content-between">
        <span>Leverage</span>
        <span>{{ symbol.leverage }}</span>
      </div>
    <div class="d-flex justify-content-between">
        <span>Contract Size</span>
        <span>{{ symbol.contract_size }}</span>
      </div>
    <div class="d-flex justify-content-between">
        <span>Contract Size Multi</span>
        <span>{{ symbol.contract_size_multi }}</span>
    </div>
    <div class="d-flex justify-content-between">
        <span>Backup %</span>
        <span>{{ symbol.backup_multi }}</span>
    </div>
    <div class="d-flex justify-content-between">
        <span>Backup $</span>
        <span>{{ symbol.backup_dollar }}</span>
    </div>
      <div class="d-flex justify-content-between">
        <span>Split step</span>
        <span>{{ symbol.use_split_step }}</span>
      </div>
      <div class="d-flex justify-content-between">
        <span>Split distance</span>
        <span>{{ symbol.split_distance }}</span>
      </div>
      <div class="d-flex justify-content-between">
        <span>Split distance perc</span>
        <span>{{ symbol.split_distance_perc }}</span>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import { useRoute } from 'vue-router'
import SymbolService from "@/services/symbol.service";
export default {
  name: "SymbolsView",
  data() {
    return {
      name: 'Bybit',
      exchangeId: useRoute().path.split('/')[2],
      symbols: [
        {
          "sid": 1,
          "display_name": "Symbol",
          "name": 'BTCUSD',
          "active": false,
          "profit_perc": 0,
          "init_start_contract_size": 132,
          "init_start_split_contract_size": 6,
          "contract_multi": 3,
          "leverage": 4,
          "contract_size": 1,
          "contract_size_multi": 0,
          "backup_dollar": 0,
          "backup_multi": 0.8,
          "use_split_step": true,
          "split_distance": 80,
          "split_distance_perc": 0

        }
      ]
    }
  },
  methods: {
    getSymbols: async function(){
      this.symbols = await SymbolService.getAllSymbols(this.exchangeId)
    }
  },
  mounted() {
    this.getSymbols()
  }
}
</script>

<style scoped>

</style>
