<template>
  <div class="col-4">
    <h1>Edit {{symbol.display_name}}</h1>
    <div>
      <form>
        <div class="form-group my-2">
          <label for="nameInput">Name</label>
          <input type="text" class="form-control" id="nameInput" placeholder="My first symbol" required v-model="symbol.display_name">
        </div>
        <div class="form-group my-2">
          <label for="nameInput">Pair Name</label>
          <input type="text" class="form-control" id="nameInput" placeholder="ETHUSD" required v-model="symbol.name">
          <small id="symbolInfo" class="form-text text-muted">Check correct pair name!</small>
        </div>
        <div class="row">
          <div class="form-group my-2 col">
            <label for="profitPercInput">Profit Percentage</label>
            <input type="number" class="form-control" id="profitPercInput"  placeholder="0.8" required v-model="symbol.profit_perc">
          </div>
          <div class="form-group my-2 col">
            <label for="startContractsInput">Start Contracts</label>
            <input type="number" class="form-control" id="startContractsInput" placeholder="132" required v-model="symbol.init_start_contract_size">
          </div>
          <div class="form-group my-2 col">
            <label for="startSplitContractsInput">Start Split Contracts</label>
            <input type="number" class="form-control" id="startSplitContractsInput" placeholder="6" required v-model="symbol.init_start_split_contract_size">
          </div>
        </div>
        <div class="row">
          <div class="form-group my-2 col">
            <label for="contractMultiInput">Contract Multi</label>
            <input type="number" class="form-control" id="contractMultiInput" placeholder="3" required v-model="symbol.contract_multi">
          </div>
          <div class="form-group my-2 col">
            <label for="leverageInput">Leverage</label>
            <input type="number" class="form-control" id="leverageInput" placeholder="4" required v-model="symbol.leverage">
          </div>
        </div>
        <div class="row">
          <div class="form-group my-2 col">
            <label for="backupMultiInput">Backup Multi</label>
            <input type="number" class="form-control" id="backupMultiInput" placeholder="0.80" required v-model="symbol.backup_multi">
          </div>
          <div class="form-group my-2 col">
            <label for="backupDollarInput">Backup $</label>
            <input type="number" class="form-control" id="backupDollarInput" placeholder="0" required v-model="symbol.backup_dollar">
          </div>
        </div>
        <div class="row">
          <div class="form-group my-2 col">
            <label for="contractSizeInput">Contract Size $</label>
            <input type="number" class="form-control" id="contractSizeInput" placeholder="1" required v-model="symbol.contract_size">
          </div>
          <div class="form-group my-2 col">
            <label for="contractMultiInput">Contract Multi</label>
            <input type="number" class="form-control" id="contractMultiInput" placeholder="0" required v-model="symbol.contract_size_multi">
          </div>
        </div>
        <div class="row">
          <div class="form-group my-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="splitStepInput" v-model="symbol.use_split_step">
            <label class="form-check-label" for="splitStepInput">
              Split Step
            </label>
          </div>
        </div>
          <div class="row">
          <div class="form-group my-2 col">
            <label for="splitDistanceInput">Split Distance $</label>
            <input type="number" class="form-control" step="any" id="splitDistanceInput" required placeholder="40" v-model="symbol.split_distance">
          </div>
          <div class="form-group my-2 col">
            <label for="splitDistancePercInput">Split Distance %</label>
            <input type="number" class="form-control" step="any" id="splitDistancePercInput" required placeholder="0" v-model="symbol.split_distance_perc">
          </div>
        </div>
        </div>

        <!--<div class="form-group my-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="trailingStopInput" v-model="trailingStop">
            <label class="form-check-label" for="trailingStopInput">
              Trailing stop
            </label>
          </div>
        </div>-->
        <!--<div class="form-group my-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="compoundingInput" v-model="compounding">
            <label class="form-check-label" for="compoundingInput">
              Compounding
            </label>
          </div>
        </div>-->

        <button type="submit" class="btn btn-primary my-2" @click.prevent="save">Save changes</button>
        <div class="form-group my-2">
          <label for="activeInput">Active</label>
          <input type="text" class="form-control" id="activeInput" aria-describedby="activeHelp" placeholder="false" disabled v-model="symbol.is_active">
          <small id="activeHelp" class="form-text text-muted">Change status by pressing buttons.</small>
        </div>
        <button type="submit" class="btn btn-primary my-2" @click.prevent="activate">Activate Symbol</button>
        <button type="submit" class="btn btn-primary my-2" @click.prevent="deactivate">Deactivate Symbol</button>
        <div class="form-group my-2">
          <button type="submit" class="btn btn-primary my-2 bg-danger" @click.prevent="deleteSy">Delete Symbol</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import SymbolService from "@/services/symbol.service";

export default {
  name: "SymbolEditView",
  data() {
    return {
      exchangeId: parseInt(useRoute().path.split('/')[2]),
      sid: parseInt(useRoute().path.split('/')[4]),
      symbol: [{
        display_name: 'TestName',
        name: '',
        active: false,

        profit_perc: null,
        init_start_contract_size: null,
        init_start_split_contract_size: null,
        contract_multi: null,
        leverage: null,

        backup_multi: null,
        backup_dollar:0,

        contract_size: null,
        contract_size_multi: 0,

        use_split_step: false,
        split_distance: 0,
        split_distance_perc: 0
      }]
    }
  },
  methods: {
    save: async function() {
      // store changes in database
      await SymbolService.adjustSymbol(this.sid, this.symbol.display_name, this.symbol.name, this.symbol.profit_perc, this.symbol.init_start_contract_size,
      this.symbol.init_start_split_contract_size, this.symbol.contract_multi, this.symbol.leverage, this.symbol.backup_multi, this.symbol.backup_dollar, this.symbol.contract_size,
      this.symbol.contract_size_multi, this.symbol.use_split_step, this.symbol.split_distance, this.symbol.split_distance_perc)

      this.$router.push('/exchange/'+ this.exchangeId +'/symbols')
    },
    activate: async function(){
      await SymbolService.activateSymbol(this.sid)
       this.$router.push('/exchange/'+ this.exchangeId +'/symbols')
    },
    deactivate: async function(){
      await SymbolService.deactivateSymbol(this.sid)
       this.$router.push('/exchange/'+ this.exchangeId +'/symbols')
    },
    deleteSy: async function(){
      await SymbolService.deleteSymbol(this.sid)
       this.$router.push('/exchange/'+ this.exchangeId +'/symbols')
      },
    getCurrentSymbol: async function(){
      this.symbol = await SymbolService.getSymbol(this.sid)
    }
  },
  mounted() {
    this.getCurrentSymbol()
  }
}
</script>

<style scoped>

</style>
