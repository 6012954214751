<template>
<div>
  <div class="d-flex flex-md-row flex-column">
    <div class="col-md-9 col-12">
      <h1>Dashboard</h1>
      <div class="d-flex justify-content-between flex-md-row flex-column me-md-4">
        <DashboardCardComponent name="Balance" :value="balance"></DashboardCardComponent>
        <DashboardCardComponent name="Positions 24h" :value="pos_24"></DashboardCardComponent>
        <DashboardCardComponent name="Active exchanges" :value="active_exchanges"></DashboardCardComponent>
      </div>
    </div>
    <div class="col-md-3 col-12">
      <div class="p-4 border rounded">
        <p>Open positions</p>
        <div v-for="exchange in open_pos" :key="exchange.name" class="py-2">
          <p>{{ exchange.name }}</p>
          <hr/>
          <div class="d-flex justify-content-between" v-for="pos in exchange.pos" :key="pos.symbol">
            <p>{{pos.symbol}}</p>
            <p>{{pos.contracts}} Cts</p>
            <p class="text-end">{{pos.refresh}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import DashboardCardComponent from "@/components/DashboardCardComponent";
import axios from "axios";

export default {
  name: "DashboardView",
  components: {
    DashboardCardComponent
  },
  data() {
    return {
      balance: 85756.73,
      pos_24: 12,
      active_exchanges: 3,
      open_pos: [
        {
          name: 'BybitInstance1',
          pos: [
            {
              symbol: 'ETHUSD',
              contracts: 10,
              refresh: '2h ago'
            },
            {
              symbol: 'BTCUSD',
              contracts: 200,
              refresh: '4h ago'
            }
          ]
        },
        {
          name: 'BybitInstance2',
          pos: [
            {
              symbol: 'ETHUSD',
              contracts: 10,
              refresh: '2h ago'
            }
          ]
        }
      ]
    }
  },
  methods: {
    getExchanges() {
      axios.post('http://localhost:8000/getExchanges', {
        userId: 1
      })
    }
  }

}
</script>

<style scoped>

</style>
